<script lang="ts">
	export let videoUrl: string,
		videoTitle: string,
		videoDescription: string,
		tags: string[],
		thumbnail: string,
		isCard: boolean;

	function htmlToElement(html) {
		var template = document.createElement("template");
		html = html.trim();
		template.innerHTML = html;
		return template.content.firstChild;
	}

	async function oEmbedVideo() {
		try {
			return await fetch(
				`http://www.youtube.com/oembed?url=${videoUrl}&format=json`,
			)
				.then((resp) => resp.json())
				.then((data) => {
					let iframe = htmlToElement(data.html);
					iframe.removeAttribute("width");
					iframe.removeAttribute("height");
					iframe.classList.add("w-full");
					iframe.classList.add("rounded-xl");
					data.html = iframe.outerHTML;
					return data;
				});
		} catch {
			return null;
		}
	}
</script>

<div class="flex w-full flex-col place-content-start {isCard ? 'card' : ''}">
	<a class="aspect-h-9 aspect-w-16" href={videoUrl} target="_blank">
		<div class="h-full w-full">
			<img
				class="z-0 h-full w-full rounded-2xl object-cover"
				alt="Video"
				src={thumbnail}
			/>
			<img
				class="absolute bottom-0 left-0 right-0 top-0 m-auto"
				alt=""
				src="/img/videos/play.png"
			/>
		</div>
	</a>

	{#if !isCard}
		<div class="mt-1 flex flex-row flex-wrap">
			{#each tags as tag}
				<div
					class="mr-1 mt-1 rounded-md bg-think-100 px-1 py-0.5 text-think-700"
				>
					{tag}
				</div>
			{/each}
		</div>
	{/if}

	<a
		class="mt-2 line-clamp-2 text-2xl font-semibold text-gray-brand-500"
		href={videoUrl}
		target="_blank"
	>
		{videoTitle || ""}
	</a>

	{#if isCard}
		<span class="mt-3 line-clamp-3 text-gray-brand-500">
			{videoDescription || ""}
		</span>
	{/if}
</div>

<style>
	.card {
		@apply rounded-2xl p-5;
	}
</style>
